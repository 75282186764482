import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { mediumURL } from "../../content_option";
import MediumCard from "../mediumcard";

export const RecentBlogs = ({activeArticle}) => {
    const [mediumData, setMediumData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        setError(null);
        fetch(
            mediumURL
        )
            .then((res) => res.json())
            .then((response) => {
                setMediumData(response.items);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err);
                setIsLoading(false);
            });
    }, []);

    const filteredMediumData = mediumData.filter(article => article.guid !== activeArticle);

    // const filteredArticles = articles.filter(article => article.id !== activeArticleId);


    return (
        <>

            <Row className="mb-5 mt-3">
                <Col lg="8" md="8">
                    <h2 className="display-4 mb-4"> Related Posts </h2>
                    <hr className="t_border my-4 ml-0 text-left" />
                </Col>
            </Row>

            {isLoading && <p>Fetching data from Medium...</p>}
            {error && <p>There was an error fetching the data: {error.message}</p>}
            {!isLoading && !error && (
                <Row>
                    {filteredMediumData
                        .sort(() => Math.random() - 0.5) // shuffle the array
                        .slice(0, 3) // get first 3 elements
                        .map((article) => (
                            <Col xs="12" className="mb-3" key={article.guid}>
                                <MediumCard article={article} />
                            </Col>
                        ))}
                </Row>
            )}
        </>
    );
};