import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagManager from "react-gtm-module";
 
const tagManagerArgs = {
    gtmId: 'GTM-5BCZCKN'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  // <HashRouter>
  //   <App />
  // </HashRouter>,
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
  // document.addEventListener('contextmenu', event => event.preventDefault())
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
