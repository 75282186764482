import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { mediumURL } from "../../content_option";
import MediumCard from "../mediumcard";

const MediumArticles = () => {
    const [mediumData, setMediumData] = useState([]);
    const [displayCount, setDisplayCount] = useState(6);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        fetch(
            mediumURL
        )
            .then(res => res.json())
            .then(response => {
                setMediumData(response.items);
                setHasMore(response.items.length > displayCount);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err);
                setIsLoading(false);
            });
    }, [displayCount]);

    const handleLoadMore = () => {
        setDisplayCount(prevCount => prevCount + 3);
    };

    return (
        <>
            {isLoading && <p>Fetching data from Medium...</p>}
            {error && <p>There was an error fetching the data: {error.message}</p>}
            {!isLoading && !error && (
                <>
                    <Row>
                        {mediumData.slice(0, displayCount).map((article) => (
                            <Col lg="12" md="4" className="mb-3" key={article.guid}>
                                <MediumCard article={article} />
                            </Col>
                        ))}
                    </Row>

                    <div className="py-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {hasMore && (
                            <div id="button_p" className="ac_btn btn " onClick={handleLoadMore}>
                                Load more
                                <div className="ring one"></div>
                                <div className="ring two"></div>
                                <div className="ring three"></div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default MediumArticles;
