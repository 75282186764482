import React, { useEffect, useState } from "react";
import "./style.css";
import Typewriter from "typewriter-effect";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { client } from "../../api/client";

export const Home = () => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'intro',
          // Add any other query parameters if needed
        });
        setData(response.items[0]?.fields || null);
      } catch (error) {
        console.error("Error fetching Contentful data:", error);
      }
    };
    fetchData();
  }, []);


  return (
      <section id="home" className="home">
        <MediaQuery maxWidth={759}>
          <Container className="hero-desc-m-p intro_sec d-block d-lg-flex justify-content-center ">
            <div>
              <div className="order-1 order-lg-2 d-lg-flex justify-content-center mb-5">
                <Image className="h-image-size" src={data?.profilePicture?.fields.file.url} alt="Asiful Alam Fahim" roundedCircle />
              </div>
              <div className="order-2 order-lg-1 d-lg-flex justify-content-center">
                <div className="intro mx-auto">
                  <h2 className="mb-1x">{data?.myName}</h2>
                  <h1 className="fluidz-48 mb-1x">
                    <Typewriter
                      options={{
                        strings: data?.highlights || [],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10,
                      }}
                    />
                  </h1>
                  <p className="mb-1x">{data?.description}</p>
                  <div className="intro_btn-action pb-5">
                    <Link smooth to="/about" className="text_2">
                      <div id="button_p" className="ac_btn btn mb-3">
                        More About Me
                        <div className="ring one"></div>
                        <div className="ring two"></div>
                        <div className="ring three"></div>
                      </div>
                    </Link>
                    <Link smooth to="/contact" className="text_2">
                      <div id="button_h" className="ac_btn btn">
                        Contact Me
                        <div className="ring one"></div>
                        <div className="ring two"></div>
                        <div className="ring three"></div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </MediaQuery>
        <MediaQuery minWidth={760}>
          <Container className="hero-desc-m-p">
            <div className="hero-middle-lg">
              <div className="order-1 order-lg-2 d-lg-flex justify-content-center">
                <Image className="h-image-size" loading="lazy" src={data?.profilePicture?.fields.file.url} roundedCircle />
              </div>
              <div className="order-2 order-lg-1 d-lg-flex justify-content-center">
                <div className="intro mx-auto">
                  <h2 className="mb-1x">{data?.myName}</h2>
                  <h1 className="fluidz-48 mb-1x">
                    <Typewriter
                      options={{
                        strings: data?.highlights || [],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10,
                      }}
                    />
                  </h1>
                  <p className="mb-1x">{data?.description}</p>
                  <div className="intro_btn-action pb-5">
                    <Link smooth to="/about" className="text_2">
                      <div id="button_p" className="ac_btn btn ">
                        More About Me
                        <div className="ring one"></div>
                        <div className="ring two"></div>
                        <div className="ring three"></div>
                      </div>
                    </Link>
                    <Link smooth to="/contact" className="text_2">
                      <div id="button_h" className="ac_btn btn">
                        Contact Me
                        <div className="ring one"></div>
                        <div className="ring two"></div>
                        <div className="ring three"></div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </MediaQuery>
      </section>
  );
};
