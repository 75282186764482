import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import './style.css';
import { client } from "../../api/client";

export const Resources = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: 'resources',
                    // Add any other query parameters if needed
                });
                setData(response.items[0]?.fields || null);
            } catch (error) {
                console.error("Error fetching Contentful data:", error);
            }
        };
        fetchData();
    }, []);

    const desc = data?.description;

    return (
        <HelmetProvider>
            <section id="resource" className="resource">
                <Container className="mb-5">
                    <Helmet>
                        <meta charSet="utf-8" />
                        {data?.title && <title>{data.title}</title>}
                        <meta name="keywords" content={data?.metaKeywords} />
                        <meta name="description" content={data?.metaDescription} />
                    </Helmet>
                    <Row className="mb-5 mt-3">
                        <Col lg="8" md="8">
                            <h2 className="display-4 mb-4"> {data?.title} </h2>
                            <hr className="t_border my-4 ml-0 text-left" />
                        </Col>
                    </Row>
                    <article className="prose prose-lg" style={{ maxWidth: '100%', marginBottom: '4rem' }}>
                        <div dangerouslySetInnerHTML={{ __html: desc }} />
                    </article>
                    <div>
                        <Row>
                            {data?.cardTitle?.map((title, id) => (
                                <Col lg="4" md="4" className="mb-3" key={id}>
                                    <Card className="resource-card">
                                        <Card.Body>
                                            <Card.Title>{title}</Card.Title>
                                            <Card.Text>{data.cardDesc[id]}</Card.Text>
                                            <a href={data.cardLink[id]} style={{ textDecoration: 'underline', textTransform: 'uppercase', color: 'black' }}>
                                                <p>{data.cardHighlight[id]}</p>
                                            </a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </section>
        </HelmetProvider>
    );
};
