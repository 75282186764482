import React from "react";
import { Route } from "react-router-dom";
import { Socialicons } from "../components/socialicons";
import Main from "../pages/main";
import AboutMain from "../pages/aboutmain";
import ContactMain from "../pages/contactmain";
import ResourceMain from "../pages/resourcemain";
import PrivacyMain from "../pages/privacymain";
import FullArticle from "../pages/posts/FullArticle";
import AllArticles from "../pages/articles";

function AppRoutes() {
  return (
    <div className="s_c">
         <Route exact path="/" component={Main} />

         <Route path="/about" component={AboutMain} />
         <Route path="/resources" component={ResourceMain} />
         <Route path="/about#portfolio" component={AboutMain} />

         <Route exact path="/articles" component={AllArticles} />
         <Route exact path="/articles/:title" component={FullArticle} /> 

         <Route path="/contact" component={ContactMain} />
         <Route path="/privacy" component={PrivacyMain} />
         {/* <Route path="*" component={Home} /> */}
      {/* <AnimatedSwitch /> */}
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
