import React, { useEffect, useState } from "react";
import "./style.css";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { socialprofils } from "../content_option";
import Themetoggle from "../components/themetoggle";
import { HashLink } from 'react-router-hash-link';
import { client } from "../api/client";

const Headermain = () => {
  const [isActive, setActive] = useState("false");
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'home',
          // Add any other query parameters if needed
        });
        setData(response.items[0]?.fields || null);
      } catch (error) {
        console.error("Error fetching Contentful data:", error);
      }
    };
    fetchData();
  }, []);

  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <>
      <header className="fixed-top site__header">
        <div className="d-flex align-items-center justify-content-between">
          <Link className="navbar-brand nav_ac" to="/">
            {data?.navLogo}
          </Link>
          <div className="d-flex align-items-center">
            <Themetoggle />
            <button className="menu__button nav_ac" onClick={handleToggle}>
              {!isActive ? <VscClose /> : <VscGrabber />}
            </button>

          </div>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item">
                    <Link smooth onClick={handleToggle} to="/" className="my-3">Home</Link>
                  </li>
                  <li className="menu_item">
                    <Link smooth onClick={handleToggle} to="/about" className="my-3">About</Link>
                  </li>
                  <li className="menu_item">
                    <a smooth href={"https://medium.com/@asifulalamfahim"} target="_blank" rel="noreferrer" className="my-3">Blogs</a>
                  </li>
                  <li className="menu_item">
                    <Link smooth onClick={handleToggle} to="/resources" className="my-3">Resources</Link>
                  </li>
                  <li className="menu_item">
                    <HashLink smooth onClick={handleToggle} to="/about#portfolio" className="my-3">Portfolio</HashLink>
                  </li>
                  <li className="menu_item">
                    <Link smooth onClick={handleToggle} to="/contact" className="my-3">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
              <a href={socialprofils.github} target="_blank" rel="noreferrer">Github</a>
              <a href={socialprofils.medium} target="_blank" rel="noreferrer">Medium</a>
              <a href={socialprofils.linkedin} target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
            <p className="copyright m-0"><span>&#169; <a href="https://asifulalam.me/">Asiful Alam</a></span></p>
          </div>
        </div>
      </header>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>

    </>
  );
};

export default Headermain;
