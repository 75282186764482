const contentful = require('contentful')

export const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: 'master', // defaults to 'master' if not set
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  host: 'https://preview.contentful.com',
})



// import { createClient } from "contentful";
// client.getEntry('2d6MZ5yhAiZFaiHpOEDfJs')
//   .then((entry) => console.log(entry))
//   .catch(console.error)

// export default useContentful = () => {
//   const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
//   const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID;

//   const client = createClient({
//     space: space,
//     accessToken: accessToken,
//     host: "https://preview.contentful.com",
//     environment: "master",
//   });

//   const getIntro = async () => {

//     try {
//       const data = await client.getIntro({
//         content_type: "intro",
//         select: "fields"
//       });
//       const sanitizedData = data.items.map((item) => {
//         return {
//           ...item.fields 
//         };
//       }); 
//       return sanitizedData;
//     } catch (error) {
//       console.log(`Error fetching users ${error}`);
//     }

//   };
//   return { getIntro };
// }
