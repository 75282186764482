import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { SiVisualstudiocode, SiCss3, SiSass, SiHtml5, SiPython, SiDjango, SiReact, SiJavascript, SiBootstrap, SiMongodb, SiFigma, SiCanva, SiMicrosoftoffice, SiWordpress, SiGithub, SiNumpy, SiPandas, SiJupyter, SiAdobelightroom, SiShotcut, SiStrapi, SiNextdotjs, SiMaterialdesign, SiPytorch, SiNetlify, SiTailwindcss } from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import MediaQuery from "react-responsive";
import { client } from "../../api/client";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const About = () => {

  const [data, setData] = useState(null);
  const [coursesData, setCoursesData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await client.getEntries({
          content_type: 'aboutMe',
          // Add any other query parameters if needed
        });
        setData(response.items[0]?.fields || null);

        const coursesResponse = await client.getEntries({
          content_type: 'coursesAndCertifications',
          // Add any other query parameters if needed
        });
        setCoursesData(coursesResponse.items[0]?.fields || null);

      } catch (error) {
        console.error("Error fetching Contentful data:", error);
      }
    };
    fetchData();
  }, []);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.DOCUMENT]: (node, children) => <div>{children}</div>,
      [BLOCKS.HR]: () => <hr />,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { description, file } = node.data.target.fields;
        const imageUrl = file.url;
        return <img src={imageUrl} alt={description} />;
      },
      // Add more custom renderings for other block types if needed
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    }
  };

  return (
    <HelmetProvider>
      <section id="about">
        <Container className="About-header py-3">
          <Helmet>
            <meta charSet="utf-8" />
            {data?.mainTitle && <title>{data.mainTitle}</title>}
            <meta name="keywords" content={data?.metaKeywords} />
            <meta name="description" content={data?.metaDescription} />
          </Helmet>
          <Row className="mb-5 mt-3">
            <Col lg="8" md="8">
              <h1 className="display-4 mb-4">{data?.mainTitle}</h1>
              <hr className="t_border my-4 ml-0 text-left" />
            </Col>
          </Row>
          <Row className="sec_sp">
            <Col lg="5">
              <h3 className="color_sec py-4">{data?.title}</h3>
            </Col>
            <Col lg="7" className="d-flex align-items-center">
              <div>
                {data?.description?.content && (
                  documentToReactComponents(
                    {
                      nodeType: 'document',
                      content: data.description.content,
                    },
                    options
                  )
                )}
              </div>
            </Col>
          </Row>
          <Row className=" sec_sp">
            <Col lg="5">
              <h3 className="color_sec py-4">{coursesData?.title}</h3>
            </Col>
            <Col lg="7">
              <table className="table caption-top">
                <tbody>
                  {coursesData?.courseTitle?.map((title, i) => (
                    <tr key={i}>
                      <td>{title}</td>
                      <td>{coursesData.platform[i]}</td>
                      <td>{coursesData.year[i]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="sec_sp">
            <Col lg="5">
              <h3 className="color_sec py-4">technical skills</h3>
            </Col>
            <MediaQuery minWidth={1024}>
              <Col lg="7">
                <div>
                  <Row>
                    <Col lg={2} align="center">
                      <SiReact size={65} />
                      <p className="mt-2 s-t-f-z">
                        React
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiJavascript size={65} />
                      <p className="mt-2 s-t-f-z">
                        JavaScript
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiNextdotjs size={65} />
                      <p className="mt-2 s-t-f-z">
                        Next Js
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <DiNodejs size={65} />
                      <p className="mt-2 s-t-f-z">
                        NodeJs
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiPython size={65} />
                      <p className="mt-2 s-t-f-z">
                        Python
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiDjango size={65} />
                      <p className="mt-2 s-t-f-z">
                        django
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiMongodb size={65} />
                      <p className="mt-2 s-t-f-z">
                        MongoDB
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiFigma size={65} />
                      <p className="mt-2 s-t-f-z">
                        Figma
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiCanva size={65} />
                      <p className="mt-2 s-t-f-z">
                        Canva
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiNetlify size={65} />
                      <p className="mt-2 s-t-f-z">
                        Netlify
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiMicrosoftoffice size={65} />
                      <p className="mt-2 s-t-f-z">
                        Office 365
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiWordpress size={65} />
                      <p className="mt-2 s-t-f-z">
                        Wordpress
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiGithub size={65} />
                      <p className="mt-2 s-t-f-z">
                        Github
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiNumpy size={65} />
                      <p className="mt-2 s-t-f-z">
                        numpy
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiPandas size={65} />
                      <p className="mt-2 s-t-f-z">
                        pandas
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiPytorch size={65} />
                      <p className="mt-2 s-t-f-z">
                        Pytorch
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiHtml5 size={65} />
                      <p className="mt-2 s-t-f-z">
                        HTML5
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiSass size={65} />
                      <p className="mt-2 s-t-f-z">
                        Sass
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiCss3 size={65} />
                      <p className="mt-2 s-t-f-z">
                        CSS3
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiTailwindcss size={65} />
                      <p className="mt-2 s-t-f-z">
                        Tailwindcss
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiBootstrap size={65} />
                      <p className="mt-2 s-t-f-z">
                        Bootstrap
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiMaterialdesign size={65} />
                      <p className="mt-2 s-t-f-z">
                        Material UI
                      </p>
                    </Col>
                    <Col lg={2} align="center">
                      <SiStrapi size={65} />
                      <p className="mt-2 s-t-f-z">
                        Strapi
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
              <Col lg="7">
                <div>
                  <Row>
                    <Col align="center">
                      <SiReact size={65} />
                      <p className="mt-2 s-t-f-z">
                        React
                      </p>
                    </Col>
                    <Col align="center">
                      <SiJavascript size={65} />
                      <p className="mt-2 s-t-f-z">
                        JavaScript
                      </p>
                    </Col>
                    <Col align="center">
                      <SiNextdotjs size={65} />
                      <p className="mt-2 s-t-f-z">
                        Next Js
                      </p>
                    </Col>
                    <Col align="center">
                      <DiNodejs size={65} />
                      <p className="mt-2 s-t-f-z">
                        NodeJs
                      </p>
                    </Col>
                    <Col align="center">
                      <SiPython size={65} />
                      <p className="mt-2 s-t-f-z">
                        Python
                      </p>
                    </Col>
                    <Col align="center">
                      <SiDjango size={65} />
                      <p className="mt-2 s-t-f-z">
                        django
                      </p>
                    </Col>
                    <Col align="center">
                      <SiMongodb size={65} />
                      <p className="mt-2 s-t-f-z">
                        MongoDB
                      </p>
                    </Col>
                    <Col align="center">
                      <SiFigma size={65} />
                      <p className="mt-2 s-t-f-z">
                        Figma
                      </p>
                    </Col>
                    <Col align="center">
                      <SiCanva size={65} />
                      <p className="mt-2 s-t-f-z">
                        Canva
                      </p>
                    </Col>
                    <Col align="center">
                      <SiNetlify size={65} />
                      <p className="mt-2 s-t-f-z">
                        Netlify
                      </p>
                    </Col>
                    <Col align="center">
                      <SiMicrosoftoffice size={65} />
                      <p className="mt-2 s-t-f-z">
                        Office 365
                      </p>
                    </Col>
                    <Col align="center">
                      <SiWordpress size={65} />
                      <p className="mt-2 s-t-f-z">
                        Wordpress
                      </p>
                    </Col>
                    <Col align="center">
                      <SiGithub size={65} />
                      <p className="mt-2 s-t-f-z">
                        Github
                      </p>
                    </Col>
                    <Col align="center">
                      <SiNumpy size={65} />
                      <p className="mt-2 s-t-f-z">
                        numpy
                      </p>
                    </Col>
                    <Col align="center">
                      <SiPandas size={65} />
                      <p className="mt-2 s-t-f-z">
                        pandas
                      </p>
                    </Col>
                    <Col align="center">
                      <SiHtml5 size={65} />
                      <p className="mt-2 s-t-f-z">
                        HTML5
                      </p>
                    </Col>
                    <Col align="center">
                      <SiSass size={65} />
                      <p className="mt-2 s-t-f-z">
                        Sass
                      </p>
                    </Col>
                    <Col align="center">
                      <SiCss3 size={65} />
                      <p className="mt-2 s-t-f-z">
                        CSS3
                      </p>
                    </Col>
                    <Col align="center">
                      <SiTailwindcss size={65} />
                      <p className="mt-2 s-t-f-z">
                        Tailwindcss
                      </p>
                    </Col>
                    <Col align="center">
                      <SiBootstrap size={65} />
                      <p className="mt-2 s-t-f-z">
                        Bootstrap
                      </p>
                    </Col>
                    <Col align="center">
                      <SiMaterialdesign size={65} />
                      <p className="mt-2 s-t-f-z">
                        Material UI
                      </p>
                    </Col>
                    <Col align="center">
                      <SiStrapi size={65} />
                      <p className="mt-2 s-t-f-z">
                        Strapi
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </MediaQuery>
          </Row>
          <Row className="sec_sp">
            <Col lg="5">
              <h3 className="color_sec py-4">tools</h3>
            </Col>
            <MediaQuery minWidth={1024}>
              <Col lg="7">
                <Row>
                  <Col lg={2} align="center">
                    <SiVisualstudiocode size={65} />
                    <p className="mt-2 s-t-f-z">
                      VSCode
                    </p>
                  </Col>
                  <Col lg={2} align="center">
                    <SiJupyter size={65} />
                    <p className="mt-2 s-t-f-z">
                      Jupyter NB
                    </p>
                  </Col>
                  <Col lg={2} align="center">
                    <SiAdobelightroom size={65} />
                    <p className="mt-2 s-t-f-z">
                      LightRoom
                    </p>
                  </Col>
                  <Col lg={2} align="center">
                    <SiShotcut size={65} />
                    <p className="mt-2 s-t-f-z">
                      ShotCut
                    </p>
                  </Col>
                </Row>
              </Col>
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
              <Col lg="7">
                <Row>
                  <Col align="center">
                    <SiVisualstudiocode size={65} />
                    <p className="mt-2 s-t-f-z">
                      VSCode
                    </p>
                  </Col>
                  <Col align="center">
                    <SiJupyter size={65} />
                    <p className="mt-2 s-t-f-z">
                      Jupyter NB
                    </p>
                  </Col>
                  <Col align="center">
                    <SiAdobelightroom size={65} />
                    <p className="mt-2 s-t-f-z">
                      LightRoom
                    </p>
                  </Col>
                  <Col align="center">
                    <SiShotcut size={65} />
                    <p className="mt-2 s-t-f-z">
                      ShotCut
                    </p>
                  </Col>
                </Row>
              </Col>
            </MediaQuery>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
};