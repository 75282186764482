import React, { useEffect, useState } from "react";
import "./style.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import MediaQuery from "react-responsive";
import { client } from "../../api/client";

export const Portfolio = () => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'portfolio',
          // Add any other query parameters if needed
        });
        console.log("Contentful Data:", response);
        setData(response.items[0]?.fields || null);
      } catch (error) {
        console.error("Error fetching Contentful data:", error);
      }
    };
    fetchData();
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ],
  };

  const sliderRef = useRef(null);

  return (
    <Container fluid className="mb-5">
      <section id="portfolio">
        <Container className="About-header">
          <Row className="mb-5 mt-3">
            <Col lg="8" md="8">
              <h1 className="display-4 mb-4"> {data?.title} </h1>{" "}
              <hr className="t_border my-4 ml-0 text-left" />
            </Col>
            <MediaQuery minWidth={700}>
              <Col md="4">
                <div
                  className='py-3 d-flex justify-content-end'
                  style={{
                    gap: '15px'
                  }}>
                  <div
                    className="buttons c-i-b"
                    onClick={() => sliderRef.current.slickPrev()}
                  >
                    <IoIosArrowBack size={20} />
                  </div>
                  <div
                    className="buttons c-i-b"
                    onClick={() => sliderRef.current.slickNext()}
                  >
                    <IoIosArrowForward size={20} />
                  </div>
                </div>
              </Col>
            </MediaQuery>
          </Row>
        </Container>
        <div className="p-l">
          {data && data.imgUrl && (
            <Slider
              ref={sliderRef}
              {...settings}
            >
              {data.imgUrl.map((url, index) => (
                <div key={index} className="px-2">
                  <Card className="card-bg po_item">
                    <Card.Img
                      src={url}
                      className="portfolio-img"
                      alt={`Portfolio ${index + 1}`}
                    />
                    <Card.ImgOverlay style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                      <div className="content">
                        <p>{data.description[index]}</p>
                        <a href={data.url[index]} target="_blank" rel="noreferrer">
                          view project
                        </a>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                  <div>
                    <h4>{data.projectName[index]}</h4>
                    <p>{data.stacks[index]}</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>
    </Container>
  );
};