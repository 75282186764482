import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import AppRoutes from "./routes";
import Headermain from "../header";
import "./App.css";
import ScrollToTop from "react-scroll-to-top";
import LoadingSpinner from "../components/spinload";
import { Spotify } from "../components/spotify/Spotify";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const Scrolltotop = withRouter(_ScrollToTop);

export default function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // simulate loading time
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
    {isLoading ? <div className="loading-spinner"><LoadingSpinner /></div> : (
      <>
      <ScrollToTop smooth={true} width="20px" height="20px" />
      <Router basename={process.env.PUBLIC_URL}>
        {/* <div className="cursor__dot">
        <AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="255, 255 ,255"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />
      </div> */}
        <Scrolltotop>
          {/* <Main /> */}
          <AppRoutes />
          <Headermain />
          <Spotify/>
          {/* <Route path="/" component={Home} /> */}
          {/* <Route path="/privacy" component={Privacy} /> */}
          {/* <Socialicons /> */}
        </Scrolltotop>
      </Router>
      </>
      )}
    </>
  );
}
