import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <Container className="py-3 footer">
        <hr />
        <Row>
          <MediaQuery minWidth={601}>
            <Col>
              <div className="d-flex justify-content-start" style={{ gap: '10px', fontSize: '14px' }}>
                <Link to="/about">About</Link>&#8226;
                <Link to="/resources">Resources</Link>&#8226;
                <Link to="/contact">Contact</Link>&#8226;
                <Link to="/privacy">Privacy Policy</Link>
              </div>
            </Col>
          </MediaQuery>
          <MediaQuery maxWidth={600}>
            <Col sm={12}>
              <div className="d-flex justify-content-center" style={{ gap: '10px', fontSize: '14px' }}>
                <Link to="/about">About</Link>&#8226;
                <Link to="/resources">Resources</Link>&#8226;
                <Link to="/contact">Contact</Link>&#8226;
                <Link to="/privacy">Privacy Policy</Link>
              </div>
            </Col>
          </MediaQuery>
          <Col>
            <MediaQuery minWidth={601}>
              <div className="d-flex justify-content-end" style={{fontSize: '14px'}}>
                <span>&#169; <a href="https://asifulalam.me/">Asiful Alam</a></span>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={600}>
              <div className="d-flex justify-content-center" style={{fontSize: '14px'}}>
                <span>&#169; <a href="https://asifulalam.me/">Asiful Alam</a></span>
              </div>
            </MediaQuery>
          </Col>
        </Row>
        {/* <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
            <a href={socialprofils.facebook}>Facebook</a>
            <a href={socialprofils.github}>Github</a>
            <a href={socialprofils.twitter}>Twitter</a>
            </div>
            <p className="copyright m-0">copyright __</p>
        </div> */}
      </Container>
    </>
  );
};
