import React from 'react';
import { Footer } from '../components/footer';
import Headermain from '../header';
import { Resources } from './resources';

const ResourceMain = () => {
    return (
        <>
        <Headermain />
        <Resources />
        <Footer />
        </>
    );
};

export default ResourceMain;