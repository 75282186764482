import React, { useEffect, useState } from 'react';
import { Footer } from '../components/footer';
import Headermain from '../header';
import { Blog } from './blog';
import { Brand } from '../components/brand';
import { Home } from './home';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { client } from '../api/client';

const Main = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: 'home',
                    // Add any other query parameters if needed
                });
                setData(response.items[0]?.fields || null);
            } catch (error) {
                console.error("Error fetching Contentful data:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                {data?.websiteTitle && <title>{data.websiteTitle}</title>}
                <meta name="keywords" content={data?.metaKeywords} />
                <meta name="description" content={data?.metaDescription} />
                <meta name="author" content={data?.metaAuthor} />
                <meta property="og:title" content={data?.title} />
                <meta property="og:description" content={data?.metaDescription} />
                <meta property="og:image" content={data?.metaImage} />
                <meta property="og:url" content={data?.metaUrl} />
            </Helmet>
            <Headermain />
            <Home />
            <Brand />
            <Blog />
            <Footer />
        </HelmetProvider>
    );
};

export default Main;