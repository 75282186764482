import React from 'react';
import { Footer } from '../components/footer';
import Headermain from '../header';
import { ContactUs } from './contact';

const ContactMain = () => {
    return (
        <>
        <Headermain />
        <ContactUs />
        <Footer />
        </>
    );
};

export default ContactMain;