import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { client } from "../../api/client";

export const Brand = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: 'brand',
                    // Add any other query parameters if needed
                });
                setData(response.items[0]?.fields || null);
            } catch (error) {
                console.error("Error fetching Contentful data:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <div style={{ paddingTop: "3rem", paddingBottom: '5rem' }}>
                <section id="brand">
                    <Container className="brand-header mb-5">
                        <Row>
                            <Col lg="12" className="mb-5">
                                <h1 className="fluidz-48 mb-1x">{data?.title}</h1>
                                <p
                                    className="mb-1x padding-lg"
                                >
                                    {data?.description}
                                </p>
                            </Col>
                            <Col lg="12">
                                <Row>
                                    {data?.images?.map((brand, id) => (
                                        <Col lg="3" xs="6" className="mb-3" key={id}>
                                            <Card style={{ borderColor: 'transparent' }}>
                                                <div align="center" style={{ padding: '10px' }}>
                                                    <img src={brand.fields.file.url} alt={brand.title} className="brand-img" />
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
        </div>
    );
}