import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const MediumCard = ({ article }) => {
    
    const match = article.description.match(/<p>(.*?)<\/p>/);
    const firstThreeLines = match ? match[1].split('\n').slice(0, 3).join('\n') : '';

    return (
        <div>
            <Row className="mb-5">
                <Col lg="4" xs="12" md="5">
                    <p>
                        {moment(article.pubDate).format("MMMM Do YYYY")}
                    </p>
                </Col>
                <Col lg="8" xs="12" md="7">
                    <div>
                        <Link to={{
                            pathname: `/articles/${encodeURIComponent(article.title)}`,
                            state: { article: article }
                        }}>
                            <h4>
                                {article.title}
                            </h4>
                            <p dangerouslySetInnerHTML={{ __html: firstThreeLines }} />
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default MediumCard;
