const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@asifulalamfahim";

const logotext = "Asiful Alam.";

const meta = {
    title: "Asiful Alam Fahim - Software Engineer, Technical Writer",
    keywords: "asiful, alam, fahim, asifulalam, bangladeshi, medium, medium blogs, chatgpt, swift71, co-founder, software, engineer, software engineer, technical writer, react developer, react, python, JavaScript, js, django, bootstrap, figma, canva, web development, blog, article, medium, data science, security",
    description: "Asiful Alam Fahim is from Bangladesh. He is a tech enthusias, software engineer and technical writer. Asiful Alam Fahim website contains portfolio, Medium blogs and articles on website development, React.js, Python, JavaScript, Django, Artificial Intelligence, ChatGPT, UI/UX and many more . Explore the latest insights and trends in the software industry through my website.",
};

const metaabout = {
    title: "About | Asiful Alam Fahim",
    keywords: "Asiful Alam Fahim, Software Engineer, Certifications, Web Development, Mobile App Development, Portfolio",
    description: "Learn more about Asiful Alam Fahim, a software engineer specializing in web and mobile app development. Discover my certifications, tools I use, and portfolio of my past projects.",
};

const metacontact = {
    title: "Contact | Asiful Alam Fahim",
    keywords: "Contact, Asiful Alam Fahim, Software Engineer, Website Development, Feedback, Suggestions, Promotions, Digital Products, Medium Profile, LinkedIn",
    description: "Get in touch with Asiful Alam Fahim, a software engineer and website developer. Share feedback, suggestions, or promote your digital products on my platform or Medium profile. Contact me for website or custom software development projects.",
};

const metaresource = {
    title: "Resources | Asiful Alam Fahim",
    keywords: "software developer, technical writer, resources, tools, courses, marketplaces, affiliate program, affiliate marketing, affiliate, programming languages, frameworks, libraries, writing tools",
    description: "Discover the best resources for software developers and technical writers on this curated page. Find the tools, programming languages, frameworks, libraries and writing tools that the author has found essential in their work and recommends to others in the same field. Get inspired and stay up to date with the latest industry trends.",
};

const introdata = {
    title: "I’m Asiful Alam Fahim",
    animated: {
        first: "Software Engineer",
        second: "Data Science Enthusias",
        third: "Technical Writer",
    },
    description: " I always believe in simplicity. In a world full of creative individuals and resources such as Pinterest, Facebook and Instagram, it’s almost difficult not to get carried away and add a bit of everything to your projects. With experience, I’ve grown to learn that a great work is when there’s nothing left to take away. ",
    your_img_url: "https://res.cloudinary.com/duujg3bgt/image/upload/v1675424951/Portfolio-website/IMG_20191220_213236_452_rvqake.webp",
};

const resourcedata = [
    {
        title: "Get up to $100 Free on Fiverr",
        description: "Use the link below to get up to $100 on Fiverr Credit, my favorite marketplace to get small work done.",
        highlight: "GET $100 FIVERR CREDIT HERE",
        link: "http://www.fiverr.com/s2/ea64873411"
    },
    {
        title: "Get Started with VSCode",
        description: "I am a huge fan of this code editor - I love being able to customize the editor - changing the layout, the icons, fonts and color scheme is so easy!.",
        highlight: "GET VSCode HERE",
        link: "https://code.visualstudio.com/"
    },
    {
        title: "Free Stock Photos",
        description: "This site offers a vast collection of stunning, high-resolution images that are entirely free to use for any purpose.",
        highlight: "Try unsplash now",
        link: "https://unsplash.com/"
    },
    {
        title: "Manage Your Media Assets using Cloudinary",
        description: "This is one of the most powerful media API. Cloudinary seamlessly delivers your media through a fast content delivery network (CDN), optimized with the industry's best practices.",
        highlight: "SignUp Now!",
        link: "https://cloudinary.com/invites/lpov9zyyucivvxsnalc5/vwoz16ryyi6gr5ppdsdy?t=default"
    },
    {
        title: "Build Modern Websites with Tailwindcss",
        description: "This CSS framework turns implementing designs from a chore to a joy! I loved it very moment I used it.",
        highlight: "Give It A Try",
        link: "https://tailwindcss.com/"
    }
];

const branddata = {
    title: "Brands I've Worked With",
    description: "I have had the privilege of working with a number of brands and companies over the years, delivering high-quality work that meets their needs and expectations. Here are some of them.",
    brands: [
        {
            id: 1,
            img_url: "https://static.youthop.com/uploads/sites/2/2019/05/1200px-brac_logo.svg_-768x256.png",
            alt: "Brac",
        },
        {
            id: 2,
            img_url: "https://res.cloudinary.com/thewhiteowl/image/upload/v1680904266/atmsl-logo_hn0rrh.png",
            alt: "Asiatic TMS Limited",
        },
        {
            id: 3,
            img_url: "https://companieslogo.com/img/orig/BTI_BIG-b44e2d9e.png?t=1648308481",
            alt: "BAT",
        },
        {
            id: 4,
            img_url: "http://medventurestaffing.com/wp-content/uploads/2022/08/MedVentures-Staffing-.png",
            alt: "Medventure Staffing Solutions",
        },
    ],
};

const dataabout = {
    title: "about myself",
    aboutme: "A dreamer, a daydreamer who is practically living life, a bit out of reality. \n \n Born in 1996. Raised in Chittagong and currently living in Dhaka, the two most beautiful and developed cities of Bangladesh.",
    aboutmetwo: " \n \n In 2020, I have completed my bachelor’s degree in CSE from North South University.",
    aboutmethree: "Moreover, I am a software engineer proficient in Python, React, pandas, numpy, Bootstrap, node js, Django framework and Web designing. \n \n I’m still learning and I believe this will help me to bring the best version of me. So, what are my hobbies? Well, I enjoy taking photos. I am more enthusiastic about taking pictures of nature, people, still life and moments with which I can connect eternally.",
};

// const dataabout = {
//     title: "about myself",
//     aboutme: "A dreamer, a daydreamer who is practically living life, a bit out of reality. \n \n Born in 1996. Raised in Chittagong and currently living in Dhaka, the two most beautiful and developed cities of Bangladesh.", 
//     aboutmetwo: " \n \n In 2020, I have completed my bachelor’s degree in CSE from North South University. Apart from this, I am the co-founder of",
//     word: "Swift71",
//     link: "http://www.swift71.com",
//     aboutmethree: "It is a custom software development company that builds digital products to help small to large-scale businesses grow effectively. Moreover, I am a software engineer proficient in Python, React, pandas, numpy, Bootstrap, node js, Django framework and Web designing. \n \n I’m still learning and I believe this will help me to bring the best version of me. So, what are my hobbies? Well, I enjoy taking photos. I am more enthusiastic about taking pictures of nature, people, still life and moments with which I can connect eternally.",
// };

const worktimeline = [
    {
        jobtitle: "Office 365",
        where: "New Horizon",
        date: "2014",
    },
    {
        jobtitle: "Computer Hardware",
        where: "New Horizon",
        date: "2014",
    },
    {
        jobtitle: "Internet & Web Technology (CSE482)",
        where: "North South University",
        date: "2019",
    },
    {
        jobtitle: "Machine Learning (CSE445)",
        where: "North South University",
        date: "2020",
    },
    {
        jobtitle: "Pattern Recognition and Neural Network (CSE465)",
        where: "North South University",
        date: "2020",
    },
    {
        jobtitle: "Git and Version Control",
        where: "Udemy",
        date: "2021",
    },

];

const skills = [{
    name: "Python",
    value: 90,
    icons: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Python_icon_%28black_and_white%29.svg/1200px-Python_icon_%28black_and_white%29.svg.png",
    iconone: "https://cutewallpaper.org/24/python-logo-png/python-vector-head-white-python-logo-png-transparent-png--1600x16002301392-pngfind.png"
},
{
    name: "Djano",
    value: 85,
    icons: "https://cdn.iconscout.com/icon/free/png-256/django-3626958-3029366.png",
    icontwo: "https://cutewallpaper.org/24/python-logo-png/python-vector-head-white-python-logo-png-transparent-png--1600x16002301392-pngfind.png"
},
{
    name: "Javascript",
    value: 80,
    icons: "https://iconape.com/wp-content/png_logo_vector/bootstrap.png",
    iconthree: "https://cutewallpaper.org/24/python-logo-png/python-vector-head-white-python-logo-png-transparent-png--1600x16002301392-pngfind.png"
},
{
    name: "React",
    value: 60,
    icons: "https://icon-library.com/images/react-icon/react-icon-15.jpg"
},
{
    name: "Jquery",
    value: 85,
    icons: "https://iconape.com/wp-content/png_logo_vector/cib-mongodb.png"
},
{
    name: "JavaScript",
    icons: "https://www.freepnglogos.com/uploads/javascript-png/js-outline-javascript-logo-download-clip-art-with-transparent-25.png"
},
{
    name: "nodejs",
    icons: "https://www.shareicon.net/download/2016/06/19/603784_nodejs_512x512.png"
},
{
    name: "git",
    icons: "https://cdn-icons-png.flaticon.com/512/25/25231.png"
},
{
    name: "msoffice",
    icons: "https://www.nicepng.com/png/full/343-3437509_black-office-icon-microsoft-office-icon-black.png"
},
{
    name: "figma",
    icons: "https://cdn.iconscout.com/icon/free/png-256/figma-3445585-2878517.png"
},
{
    name: "canva",
    icons: "https://pnggrid.com/wp-content/uploads/2021/05/Canva-Logo-Black-1024x1024.png"
}
];

// const services = [{
//         title: "UI & UX Design",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
//     },
//     {
//         title: "Mobile Apps",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
//     },
//     {
//         title: "Wordpress Design",
//         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
//     },
// ];

const dataportfolio = [
    {
        img: "https://res.cloudinary.com/duujg3bgt/image/upload/v1696449576/Portfolio-website/Screenshot_2023-06-23_214934_ppnak1.png",
        desctiption: "The White Owl",
        link: "https://thewhiteowltech.com",
        title: "The White Owl",
        category: "ReactJS, Tailwindcss, Html5"
    },
    {
        img: "https://res.cloudinary.com/duujg3bgt/image/upload/v1696448991/Portfolio-website/Screenshot_2023-07-05_000716_k0afrz.png",
        desctiption: "Brac Learning Centre",
        link: "https://blc.brac.net",
        title: "Brac Learning Centre",
        category: "ReactJS, React-Bootstrap, Html5, CSS3"
    },
    {
        img: "https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
        desctiption: "Image To Cartoon",
        link: "https://github.com/Faheem2k19/image-to-cartoon",
        title: "Image to Cartoon",
        category: "Python, numpy, OpenCv2"
    },
    {
        img: "https://images.unsplash.com/photo-1624814448280-0f2d7d399b07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8c3BvdGlmeXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
        desctiption: "Spotify Music Recommendation",
        link: "https://github.com/Faheem2k19/Music-Recommendation",
        title: "Music Recommendation",
        category: "Spotify, API, Python, Machine Learning",
    },
    {
        img: "https://images.unsplash.com/photo-1576086213369-97a306d36557?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGlzZWFzZXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
        desctiption: "Malaria Detection usign Kaggle dataset",
        link: "https://github.com/Faheem2k19/Malaria-Detection",
        title: "Malaria Detection",
        category: "Python, CNN",
    },
    {
        img: "https://images.unsplash.com/photo-1509395062183-67c5ad6faff9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cG9ydGZvbGlvfGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        desctiption: "My Blog website using React",
        link: "https://asifulalam.me",
        title: "My PORTFOLIO Website",
        category: "React, React-bootstrap, Tailwiindcss, Medium API, Spotify API, Html5,"
    },
    {
        img: "https://images.unsplash.com/photo-1634224143538-ce0221abf732?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        desctiption: "A Unique Password Generator",
        link: "https://uniquepassword.netlify.app",
        title: "Password Generator",
        category: "React, Html5, Material UI, Netlify"
    },
    {
        img: "https://images.unsplash.com/photo-1546146830-2cca9512c68e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHl0aG9ufGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        desctiption: "Portfolio website using Django",
        link: "http://asifulalamf.pythonanywhere.com/",
        title: "Django PORTFOLIO",
        category: "Html5, CSS3, Bootstrap, Django",
    },
    {
        img: "https://res.cloudinary.com/duujg3bgt/image/upload/v1696449363/Portfolio-website/Screenshot_2023-10-05_015354_gakcvc.png",
        desctiption: "Medventure Staffing Solutions",
        link: "http://medventurestaffing.com",
        title: "Medventure Staffing Solutions",
        category: "php, WordPress, Elementor, Html"
    },
    {
        img: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        desctiption: "Real-Estate Website Landing Page",
        link: "https://swift-decor.herokuapp.com/",
        title: "Real-Estate",
        category: "React js, Material UI, React-Bootstrap, Styled Components",
    },
];

const contact = {
    desc: "<p>I value your feedback and suggestions regarding my blogs and would be happy to hear from you. Please do not hesitate to reach out if you have <b> a digital product or website that you would like to promote through my platform</b>. <br/> <br/> Additionally, if you have <b>any questions or would like to discuss custom software or web development project</b>, feel free to contact me <i class='fa fa-envelope'></i></p>"
}

const resource = {
    desc: "<p>This page is a curated selection of my favorite resources as a software engineer and technical writer. It features various resources that I found essential in my day-to-day work, including marketplaces, programming languages, courses, project management, and writing tools.<br /><br />I carefully chose these resources based on my experience and recommended others in the field. Whether you're a beginner or an experienced developer or writer, this page is sure to have something of value for you.</p>"
}

const socialprofils = {
    github: "https://github.com/Faheem2k19",
    medium: "https://medium.com/@asifulalamfahim",
    linkedin: "https://www.linkedin.com/in/asifulalam-fahim/",
};

const contactConfig = {
    YOUR_EMAIL: "asifulalam.fahim@gmail.com",
    YOUR_FONE: "(+880)1840-629090",
    description: "If you have any questions or would like to discuss custom software or web development project, feel free to contact me",
};
export {
    mediumURL,
    meta,
    metaabout,
    metacontact,
    metaresource,
    branddata,
    dataabout,
    resourcedata,
    dataportfolio,
    worktimeline,
    skills,
    introdata,
    contact,
    resource,
    socialprofils,
    logotext,
    contactConfig
};