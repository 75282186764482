import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Container } from "react-bootstrap";
import "./fullarticle.css";
import DOMPurify from 'dompurify';
import { BsMedium } from 'react-icons/bs';
import { RecentBlogs } from "../../components/recentblogs/RecentBlogs";
import { Footer } from "../../components/footer";
import { BiArrowBack } from "react-icons/bi";
import { Helmet, HelmetProvider } from "react-helmet-async";

const FullArticle = () => {
    const [article, setArticle] = useState(null);
    const location = useLocation();
    const { title } = useParams();
    const decodedTitle = decodeURIComponent(title);

    useEffect(() => {
        if (location.state && location.state.article) {
            setArticle(location.state.article);
        } else {
            fetch(
                process.env.REACT_APP_MEDIUM_API
            )
                .then(response => response.json())
                .then(data => {
                    const article = data.items.find(item => item.title === decodedTitle);
                    setArticle(article);
                })
                .catch(error => console.error(error));
        }
    }, [location, title, decodedTitle]);

    if (!article) {
        return <div>Loading...</div>;
    }

    const avatarUrl = article.avatar || 'https://res.cloudinary.com/duujg3bgt/image/upload/v1675424951/Portfolio-website/IMG_20191220_213236_452_rvqake.webp';

    const handleClick = () => {
        window.history.back();
    };

    const sanitizedDescription = DOMPurify.sanitize(article.description);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{article.title} | Asiful Alam Fahim</title>
                <meta name="author" content={article.author} />
                <meta property="og:title" content={article.title} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={article.description} />
                <meta property="og:image" content={article.thumbnail} />
            </Helmet>
            <div className="blog">
                <h2 style={{ fontWeight: '600' }}>{article.title}</h2>
                <div style={{ marginTop: '2rem' }}>
                    <div display="flex" className="authdetails">
                        <img src={avatarUrl} alt={article.author} />
                        <div className="authtext">
                            <div>By {article.author} </div>
                            <span className="date">
                                {moment(article.pubDate).format("MMMM d, YYYY")}
                            </span>
                        </div>
                    </div>
                </div>
                <article className="prose lg:prose-xl">
                    <div
                        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    />
                </article>
                <div className="intro_btn-action py-5">
                    <a smooth href={article.link} target="_blank" rel="noopener noreferrer" className="text_2">
                        <div id="button_p" className="ac_btn btn ">
                            <BsMedium size={25} />  &nbsp; This article was originally published on Medium.
                            <div className="ring one"></div>
                            <div className="ring two"></div>
                            <div className="ring three"></div>
                        </div>
                    </a>
                </div>

                <div className="intro_btn-action py-2">
                    <div id="button_h" className="ac_btn btn" onClick={handleClick}>
                        <BiArrowBack size={25} />  &nbsp; Back
                        <div className="ring one"></div>
                        <div className="ring two"></div>
                        <div className="ring three"></div>
                    </div>
                </div>
            </div>
            <Container className="py-2">
                <RecentBlogs activeArticle={article.guid} />
            </Container>
            <Footer />
        </HelmetProvider>
    );
};

export default FullArticle;
