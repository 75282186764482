import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { client } from "../../api/client";

export const ContactUs = () => {

  // return (
  //   <HelmetProvider>
  //     <section id="contact" className="contact">
  //       <Container className="mt-5">
  //         <Helmet>
  //           <meta charSet="utf-8" />
  //           <title>{metacontact.title}</title>
  //           <meta name="keywords" content={metacontact.keywords} />
  //           <meta name="description" content={metacontact.description} />
  //         </Helmet>
  //         <Row className="mb-5 mt-3">
  //           <Col lg="8">
  //             <h1 className="display-4 mb-4">Contact Me</h1>
  //             <hr className="t_border my-4 ml-0 text-left" />
  //           </Col>
  //         </Row>
  //         <article className="prose prose-xl m-w-contact">
  //         <div dangerouslySetInnerHTML={{ __html: contact.desc }} />
  //         </article>
  //       </Container>
  //     </section>
  //   </HelmetProvider>
  // )

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'contact',
          // Add any other query parameters if needed
        });
        console.log("Contentful Data:", response);
        setData(response.items[0]?.fields || null);
      } catch (error) {
        console.error("Error fetching Contentful data:", error);
      }
    };
    fetchData();
  }, []);


  return (
    <HelmetProvider>
      <section id="contact">
        <Container className="mt-5">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{data?.title}</title>
            <meta name="keywords" content={data?.metaKeywords} />
            <meta name="description" content={data?.metaDescription} />
          </Helmet>
          <Row className="mb-5 mt-3">
            <Col lg="8">
              <h1 className="display-4 mb-4">{data?.title}</h1>
              <hr className="t_border my-4 ml-0 text-left" />
            </Col>
          </Row>
          <Row className="sec_sp">
            <Col lg="5" md={6} className="mb-5">
              <p>
                {data?.description}
              </p>
              <address>
                <strong>Email: &nbsp;
                </strong>{" "}
                <a href={`mailto:${data?.email}`}>
                  {data?.email}
                </a>
                <br />
                <br />
                {data?.hasOwnProperty("phone") ? (
                  <p>
                    <strong>Phone: &nbsp;
                    </strong>
                    <a href={`callto:${data?.phone}`}>
                      {data?.phone}
                    </a>
                  </p>
                ) : (
                  ""
                )}
              </address>
            </Col>
            <Col lg={7} md={6} className="d-flex align-items-center">
              <form className="contact__form w-100" action="/contact" name="contact" method="POST">
                <input type="hidden" name="form-name" value="contact" />
                <Row>
                  <Col lg="6" className="form-group">
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      type="text"
                      required
                    />
                  </Col>
                  <Col lg="6" className="form-group">
                    <input
                      className="form-control rounded-0"
                      name="email"
                      placeholder="Email"
                      type="email"
                      required
                    />
                  </Col>
                </Row>
                <textarea
                  className="form-control rounded-0"
                  name="message"
                  placeholder="Message"
                  rows="5"
                  required
                ></textarea>
                <br />
                <Row>
                  <Col lg="12" className="form-group">
                    <button className="btn ac_btn" type="submit">
                      Send
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
};