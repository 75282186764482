import React from "react";
import { FaSpotify } from "react-icons/fa";
import "./style.css";

export const Spotify = () => {
  return (
    <div className="stick_spotify">
        <div className="d-flex justify-content-space-between" style={{gap: '10px'}}>
      <div className="icon">
        <FaSpotify />
      </div>
      <div className="player">
        <iframe
        title="spotify"
        style={{borderRadius: 12}} 
        src="https://open.spotify.com/embed/playlist/6phYndBIC4DIqefH1CcUsT?utm_source=generator&theme=0" 
        width="100%" 
        height={152} 
        frameBorder={0} 
        allowFullScreen 
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />

      </div>
      </div>
    </div>
  );
};
