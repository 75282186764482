import React from 'react';
import { Footer } from '../components/footer';
import Headermain from '../header';
import { About } from './about';
import { Portfolio } from './portfolio';

const AboutMain = () => {
    return (
        <>
        <Headermain />
        <About />
        <Portfolio />
        <Footer />
        </>
    );
};

export default AboutMain;