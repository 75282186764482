import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { client } from "../../api/client";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



export const Privacy = () => {

    const [data, setData] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {

          const response = await client.getEntries({
            content_type: 'privacyPolicy',
            // Add any other query parameters if needed
          });
          setData(response.items[0]?.fields || null);
  
        } catch (error) {
          console.error("Error fetching Contentful data:", error);
        }
      };
      fetchData();
    }, []);
  
    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.DOCUMENT]: (node, children) => <div>{children}</div>,
        [BLOCKS.HR]: () => <hr />,
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { description, file } = node.data.target.fields;
          const imageUrl = file.url;
          return <img src={imageUrl} alt={description} />;
        },
        // Add more custom renderings for other block types if needed
      },
      renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      }
    };

    return (
        <Container fluid className="py-5">
            <Container>
                <div>
                {data?.description?.content && (
                  documentToReactComponents(
                    {
                      nodeType: 'document',
                      content: data.description.content,
                    },
                    options
                  )
                )}
              </div>
            </Container>
        </Container>

    );
}