import React from 'react';
import { Footer } from '../components/footer';
import Headermain from '../header';
import { Privacy } from './privacy';

const PrivacyMain = () => {
    return (
        <>
        <Headermain />
        <Privacy />
        <Footer />
        </>
    );
};

export default PrivacyMain;