import React, { useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Col, Container, Row } from 'react-bootstrap';
import { Footer } from '../components/footer';
import MediumArticles from '../components/mediumarticles';
import { meta } from '../content_option';
import Headermain from '../header';
import FullArticle from './posts/FullArticle';

const AllArticles = () => {
    const [selectedArticle, setSelectedArticle] = useState(null);

    return (
        <HelmetProvider>
            <Headermain />
            {selectedArticle ? (
                <FullArticle article={selectedArticle} onBack={() => setSelectedArticle(null)} />
            ) : (
                <Container className="Blog-header mb-5">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title> {meta.title} </title>
                        <meta name="keywords" content={meta.keywords} />
                        <meta name="description" content={meta.description} />
                    </Helmet>
                    <Row className="mb-5 mt-3">
                        <Col lg="8" md="8">
                            <h2 className="display-4 mb-4"> All Blogs </h2>
                            <hr className="t_border my-4 ml-0 text-left" />
                        </Col>
                    </Row>
                    <MediumArticles onSelectArticle={article => setSelectedArticle(article)} />
                </Container>
            )}
            <Footer />
        </HelmetProvider>
    );
};

export default AllArticles;
