// import React, { useState, useEffect } from "react";
// import { Helmet, HelmetProvider } from "react-helmet-async";
// import { Container, Row, Col, Card, Image } from "react-bootstrap";
// import { meta } from "../../content_option";
// import { HiOutlineArrowNarrowRight } from "react-icons/hi";
// import SearchBar from "../../components/searchbar";
// import MediumCard from "../../components/mediumcard";

// export const Blog = () => {
//     const [mediumData, setMediumData] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [query, setQuery] = useState("");  // Add a state variable to store the search query
//     const [searchResults, setSearchResults] = useState([]);  // Add a state variable to store the search results

//     useEffect(() => {
//         fetch(
//             `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@asifulalamfahim`
//         )
//             .then((res) => res.json())
//             .then((response) => {
//                 setMediumData(response.items);
//                 setIsLoading(false);
//             })
//             .catch((err) => console.log(err));
//     }, []);

//     const searchArticles = (query) => {
//         const results = mediumData.filter((article) =>
//             article.title.toLowerCase().includes(query.toLowerCase())
//         );
//         return results;
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const results = searchArticles(query);
//         setSearchResults(results);
//     };

//     const newArray = mediumData;

//     return (
//         <HelmetProvider>
//             <section id="blog">
//                 <Container className="Blog-header mb-5">
//                     <Helmet>
//                         <meta charSet="utf-8" />
//                         <title> BLOG | {meta.title} </title>
//                         <meta name="keywords" content={meta.keywords} />
//                         <meta name="description" content={meta.description} />
//                     </Helmet>
//                     <Row className="mb-5 mt-3">
//                         <Col lg="8" md="8">
//                             <h1 className="display-4 mb-4"> Blog </h1>{" "}
//                             <hr className="t_border my-4 ml-0 text-left" />
//                         </Col>
//                     </Row>
//                     <div className="py-5">
//                         <SearchBar query={query} setQuery={setQuery} handleSubmit={handleSubmit} />
//                     </div>
//                     {isLoading && <p>Fetching data from Medium!</p>}

//                     <Row>
//                         {searchResults.length > 0
//                             ? searchResults.map((article) => (
//                                 <Col lg="4" md="4" className="mb-3" key={article.guid}>
//                                     <MediumCard article={article} />
//                                 </Col>
//                             ))
//                             : mediumData.map((article) => (
//                                 <Col lg="4" md="4" className="mb-3" key={article.guid}>
//                                     <MediumCard article={article} />
//                                 </Col>
//                             ))}
//                     </Row>
//                     <Row>
//                         <Col>
//                             <div className="intro_btn-action mt-3 mb-5">
//                                 <a smooth href="https://medium.com/@asifulalamfahim" target="_blank" rel="noopener noreferrer" className="text_2">
//                                     <div id="button_p" className="ac_btn btn ">
//                                         View All &nbsp; <HiOutlineArrowNarrowRight size={25} />
//                                         <div className="ring one"></div>
//                                         <div className="ring two"></div>
//                                         <div className="ring three"></div>
//                                     </div>
//                                 </a>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Container>
//             </section>
//         </HelmetProvider>
//     );
// };


import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import MediumCard from "../../components/mediumcard";

export const Blog = () => {

    const [mediumData, setMediumData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        fetch(
            process.env.REACT_APP_MEDIUM_API
        )
            .then(res => res.json())
            .then(response => {
                setMediumData(response.items);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err);
                setIsLoading(false);
            });
    }, []);


    return (
        <>
            {isLoading && <p>Fetching data from Medium...</p>}
            {error && <p>There was an error fetching the data: {error.message}</p>}
            {!isLoading && !error && (
                <section id="blog">
                    <Container className="Blog-header mb-5">
                        <Row className="mb-5 mt-3">
                            <Col lg="8" md="8">
                                <h2 className="display-4 mb-4"> My Latest Blogs </h2>{" "}
                                <hr className="t_border my-4 ml-0 text-left" />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12">
                                {mediumData.slice(0, 8).map((article) => (
                                    <div className="mb-3" key={article.guid}>
                                        <MediumCard article={article} />
                                    </div>
                                ))}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="intro_btn-action mt-3 mb-5">
                                    <a href={"https://medium.com/@asifulalamfahim"} target="_blank" rel="noreferrer" className="text_2">
                                        <div id="button_p" className="ac_btn btn ">
                                            View All &nbsp; <HiOutlineArrowNarrowRight size={25} />
                                            <div className="ring one"></div>
                                            <div className="ring two"></div>
                                            <div className="ring three"></div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )}
        </>
    );
};